import * as React from 'react'

import { SimpleSection } from 'sdk/sections'
import { Text, TextName } from 'sdk'

import { cases } from './data'

import classes from './style.module.scss'

export const ImplementAudio: React.FC = () => {
  return (
    <SimpleSection
      title="Implement Virtually Any Audio Use Case"
      subtitle="Through these tools, we’re unlocking advancements in
        accelerated computing, spatial audio processing, game audio,
        machine learning, and AI"
    >
      <div className={classes.cases}>
        { cases.map(({ image, title, link }, index) => (
          <a
            target="_blank"
            rel="noreferrer"
            href={link}
            key={index}
            className={classes.case}
            style={{
              backgroundImage: `url(${image})`,
            }}
          >
            <Text className={classes.title} type={TextName.H2}>
              { title }
            </Text>
          </a>
        )) }
      </div>
    </SimpleSection>
  )
}
