import React, { FC } from 'react'

import { FormPopup } from 'sdk/components'
import { useAppDispatch } from 'redux/store'
import { closePopup } from 'redux/popupReducer'

import { agreement } from './agreementHtml'

import classes from './style.module.scss'

export const EulaAgreement: FC = () => {
  const dispatch = useAppDispatch()

  return (
    <FormPopup title="End-User License Agreement" onClose={() => dispatch(closePopup())}>
      <div
        className={classes.agreement}
        dangerouslySetInnerHTML={{ __html: agreement }}
      />
    </FormPopup>
  )
}
