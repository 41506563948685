import dashboard from 'shared/assets/icons/dashboard.svg'
import waveform from 'shared/assets/icons/waveform-path.svg'
import bigData from 'shared/assets/icons/big-data-ai.svg'
import modelCubeSpace from 'shared/assets/icons/model-cube-space.svg'
import radioWaves from 'shared/assets/icons/radio-waves.svg'
import modelCube from 'shared/assets/icons/model-cube.svg'

export const useCases = [
  {
    icon: dashboard,
    title: 'Low-Latency Multi-Channel Processing',
  },
  {
    icon: waveform,
    title: 'Multi-Channel Sound Synthesis',
  },
  {
    icon: bigData,
    title: 'Real-Time Inference for Complex Models & ML Workflows',
  },
  {
    icon: modelCubeSpace,
    title: 'Acoustic Room Correction',
  },
  {
    icon: radioWaves,
    title: 'Dynamic Spatial Reverbs',
  },
  {
    icon: modelCube,
    title: 'Advanced Room Simulation',
  },
]
