import React, { FC, HTMLAttributes } from 'react'
import classNames from 'classnames'

import classes from './style.module.scss'

interface ComponentProps extends HTMLAttributes<HTMLDivElement> {
  className?: string
  accent?: boolean
}

export const GradientContainer: FC<ComponentProps> = (props) => {
  const { children, className, accent, ...rest } = props

  return (
    <div
      className={classNames(classes.container, { [classes.accent]: accent }, className)}
      {...rest}
    >
      { children }
    </div>
  )
}
