import * as React from 'react'

import { SocialIcon, SocialNetwork } from 'sdk'

import classes from './style.module.scss'

export const SocialNetworks: React.FC = () => {
  return (
    <div className={classes.container}>
      <SocialIcon name={SocialNetwork.LinkedIn} link='https://www.linkedin.com/company/gpuaudio' />
      <SocialIcon name={SocialNetwork.Facebook} link='https://www.facebook.com/GpuAudio' />
      <SocialIcon name={SocialNetwork.Instagram} link='https://www.instagram.com/gpuaudio' />
      <SocialIcon name={SocialNetwork.Twitter} link='https://twitter.com/gpuaudio' />
    </div>
  )
}
