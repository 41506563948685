import React, { FC } from 'react'

import cta from 'shared/assets/cta.jpeg'

import { Text, TextName } from '../text/Text'

import classes from './style.module.scss'

interface ComponentProps {
  text: string
  button: React.ReactNode
}

export const ContactUs: FC<ComponentProps> = (props) => {
  const { text, button } = props

  return (
    <div
      className={classes.content}
      style={{ backgroundImage: `url(${cta})` }}
    >
      <Text
        type={TextName.TextBold}
        className={classes.text}
      >
        { text }
      </Text>

      { button }
    </div>
  )
}
