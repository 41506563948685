import * as React from 'react'

import { SimpleSection } from 'sdk/sections'
import { YoutubeEmbed } from 'sdk/components'

import classes from './style.module.scss'

export const SeeNam: React.FC = () => {
  return (
    <SimpleSection
      title="Learn More About NAM"
    >
      <YoutubeEmbed
        embedId="3Vzabi1uIJU"
        className={classes.video}
      />
    </SimpleSection>
  )
}
