import * as React from 'react'

import { ProductVersionRecord } from 'core/interfaces'

import downloadIcon from './assets/download-icon.svg'

import { Text, TextName } from '../../text/Text'

import classes from './style.module.scss'

interface ComponentProps {
  versions: ProductVersionRecord[]
}

export const VersionsList: React.FC<ComponentProps> = ({ versions }) => {
  return (
    <table className={classes.productVersionTable}>
      <tbody>
        { versions.map((version, index) => (
          <tr key={index}>
            <td className={classes.cell1}>
              <a
                href={`/download/${version.id}`}
                target="_blank"
                // onClick={() => dispatch(showPopup(PopupId.Download))}
                className={classes.downloadLink}
                rel="noreferrer"
              >
                <img src={downloadIcon} alt="download icon" className={classes.downloadIcon} />
              </a>
            </td>
            <td className={classes.cell2}>
              { version.version && (
                <Text type={TextName.Subtext}>
                v{ version.version }
                </Text>
              ) }
            </td>
            <td className={classes.cell3}>
              <Text type={TextName.Subtext}>
                { version.human_release_date }
              </Text>
            </td>
            <td className={classes.cell4}>
              <Text type={TextName.Text}>
                { version.name }
              </Text>
            </td>
          </tr>
        )) }
      </tbody>
    </table>
  )
}
