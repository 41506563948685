import * as React from 'react'
import classNames from 'classnames'

import { TrackALink, UnsafeHTML } from 'components'
import { Panel } from 'pages/user-profile/components/panel/Panel'

import { ProductRecord } from 'core/interfaces'

import { VersionsList } from './versions-list/VersionsList'

import { Text, TextName } from '../text/Text'

import classes from './style.module.scss'

interface ComponentProps {
  product: ProductRecord
}

export const ProductItem: React.FC<ComponentProps> = ({ product }) => {
  return (
    <Panel
      key={product.id}
      className={classNames(
        classes.panel,
        { [classes.withBadge]: product.badge },
      )}
    >
      <Text type={TextName.H3} className={classes.name}>
        { product.name }
      </Text>

      { product.badge && (
        <div className={classes.badge}>
          { product.badge }
        </div>
      ) }

      <VersionsList versions={product.published_versions} />

      { product.description ? (
        <UnsafeHTML className={classes.description}>
          { product.description }
        </UnsafeHTML>
      ) : null }

      { product.readme_link ? (
        <TrackALink
          className={classes.readmeLink}
          trackName="Product_readme"
          href={product.readme_link}
          target="_blank"
          rel="noreferrer"
        >
          Download the README here
        </TrackALink>
      ) : null }
    </Panel>
  )
}
