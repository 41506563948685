import * as React from 'react'
import { MouseParallaxChild, MouseParallaxContainer } from 'react-parallax-mouse'

import { Content } from 'sdk/components'
import { ActionButtonGetSDK } from 'sdk/action-buttons/ActionButtonGetSDK'

import sdkImage from './assets/sdk-image.png'

import { Text, TextName } from '../../text/Text'

import classes from './style.module.scss'

export const GetOurSDK: React.FC = () => {
  return (
    <Content
      wrapperClassName={classes.wrapper}
      className={classes.container}
    >
      <div className={classes.textBlock}>
        <Text
          type={TextName.H1}
          className={classes.heading}
        >
          Build the Future of&nbsp;Audio&nbsp;With Our SDK
        </Text>

        <Text
          type={TextName.Text}
          className={classes.text}
        >
          We’re here to help you create the next generation
          of&nbsp;accelerated audio products
        </Text>

        <ActionButtonGetSDK />
      </div>
      <MouseParallaxContainer
        globalFactorX={0.015}
        globalFactorY={0.015}
        resetOnLeave
        useWindowMouseEvents
        containerStyle={{
          overflow: 'visible',
          position: 'absolute',
        }}
        className={classes.imageContainer}
      >
        <MouseParallaxChild className={classes.sdkImage}>
          <img
            src={sdkImage}
            width={1678}
            height={1232}
            alt="sdk image"
          />
        </MouseParallaxChild>
      </MouseParallaxContainer>
    </Content>
  )
}
