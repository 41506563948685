import * as React from 'react'
import { Link } from 'react-router-dom'

import { SimpleSection } from 'sdk/sections'
import { ContactUs, GradientContainer, Text, TextName } from 'sdk'
import fileBinary from 'shared/assets/icons/file-binary.svg'
import cube from 'shared/assets/icons/cube.svg'
import { Button } from 'sdk/button/Button'

import { SectionId } from 'core/interfaces'

import classes from './style.module.scss'

export const GettingStarted: React.FC = () => {
  return (
    <SimpleSection
      id={SectionId.GettingStarted}
      title="Getting Started"
      subtitle="To install the GPU Audio SDK, please follow the installation instructions"
    >
      <div className={classes.instructions}>
        <GradientContainer accent className={classes.instruction}>
          <img
            src={fileBinary}
            alt="file-binary"
            width={50}
            height={50}
          />

          <Text type={TextName.Text}>
            <strong>Download GPU Audio Binaries</strong>
            <br />
            <Link to="/sdk-binaries">
              gpu.audio/sdk-binaries
            </Link>
          </Text>
        </GradientContainer>

        <GradientContainer accent className={classes.instruction}>
          <img
            src={cube}
            alt="cube"
            width={50}
            height={50}
          />

          <Text type={TextName.Text}>
            <strong>Visit Our GitHub for More</strong>
            <br />
            <a
              href="https://github.com/gpuaudio/gpuaudio-sdk"
              target="_blank"
              rel="noreferrer"
            >
              https://github.com/gpuaudio/gpuaudio-sdk
            </a>
          </Text>
        </GradientContainer>
      </div>

      <GradientContainer className={classes.requirements}>
        <Text
          type={TextName.TextBold}
          className={classes.title}
        >
          System Requirements
        </Text>

        <div className={classes.requirementsLists}>
          <div>
            Operating systems:
            <ul>
              <li>Windows 10 22H2, 11</li>
              <li>MacOS X 13-15</li>
            </ul>
          </div>

          <div>
            GPUs support:
            <ul>
              <li>
                GeForce adapter 10XX+ (please check
                { ' ' }
                <a
                  href="https://developer.nvidia.com/cuda-gpus"
                  target="_blank"
                  rel="noreferrer"
                >
                  NVIDIA CUDA 11.8 support
                </a>
                )
              </li>
              <li>
                AMD RX 6800+ (please check
                { ' ' }
                <a
                  href="https://rocm.docs.amd.com/en/docs-5.5.1/release/windows_support.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  AMD HIP 5.5 support
                </a>
                )
              </li>
              <li>Apple Silicon M1-M4</li>
            </ul>
          </div>
        </div>

        <Text
          type={TextName.TextBold}
          className={classes.title}
        >
          Software Requirements
        </Text>

        <div className={classes.requirementsLists}>
          <div>
            Windows:
            <ul>
              <li>Visual Studio 2022 with `MSVC v143 - VS 2022 C++ x64/x86 build tools v14.34-17.4` toolchain</li>
              <li>cmake 3.26.3+</li>
              <li>git</li>
              <li>GPU Audio Binaries</li>
            </ul>
          </div>

          <div>
            MacOS:
            <ul>
              <li>Xcode</li>
              <li>cmake 3.26.3+</li>
              <li>GPU Audio Binaries</li>
            </ul>
          </div>
        </div>
      </GradientContainer>

      <ContactUs
        text="If you have any questions – contact us through our SDK Support form here:"
        button={(
          <Button
            as="link"
            target="_blank"
            href="https://forms.gle/xSirrMTuBqvd2Rnc7"
          >
            Get in touch
          </Button>
        )}
      />
    </SimpleSection>
  )
}
