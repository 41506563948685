import dashboard from 'shared/assets/icons/dashboard.svg'
import laptopCode from 'shared/assets/icons/laptop-code.svg'
import masterPlan from 'shared/assets/icons/master-plan-integrate.svg'

export const features = [
  {
    icon: dashboard,
    title: 'Benefits',
    description: 'GPU Audio SDK enables ultra-low latency, ' +
      'multi-layer processing, and access to high-performance ' +
      'DSP for seamless, real-time audio processing',
  },
  {
    icon: laptopCode,
    title: 'Cross-Compatible Development',
    description: 'Built with C++ on both host and device sides, ' +
      'the SDK supports multiple GPU vendors, using a common subset ' +
      'of C++, CUDA, Metal, and potentially OpenCL ',
  },
  {
    icon: masterPlan,
    title: 'Streamlined Integration',
    description: 'The platform standardizes GPU dialect differences ' +
      'through templates and a context object, simplifying development ' +
      'while maintaining performance',
  },
]
