import * as React from 'react'

import { ImageFeatures } from 'sdk'
import { SimpleSection } from 'sdk/sections'

import { features } from './data'
import scheme from './assets/scheme.jpeg'

export const ProgrammingYourGPU: React.FC = () => {
  return (
    <SimpleSection
      title="Programming Your GPU"
    >
      <ImageFeatures
        image={scheme}
        features={features}
      />
    </SimpleSection>
  )
}
