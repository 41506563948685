import * as React from 'react'

import { PopupId } from 'redux/popupReducer'
import { useAppSelector } from 'redux/store'
import { SimplePopup } from 'components/popups'
import { ApplyToJob, EulaAgreement, SignOut } from 'popups'
import {
  SoundscapeFeel,
  SoundscapeSafe,
  SoundscapeSmart,
  SoundscapeVoice,
  SoundscapeZone,
} from 'pages/soundscape-page/popups'

import { PopupContainer } from './PopupContainer'

import { DownloadOverlay } from '../download-overlay/DownloadOverlay'

/**
 * "Smart component" to display the correct popup based on popup redux state
 */
export const PopupContainerWithState: React.FC = () => {
  const popup = useAppSelector((state) => state.popup.activePopup)

  let popupComponent: React.ReactNode
  switch (popup) {
    case PopupId.Download:
      popupComponent = <DownloadOverlay />
      break
    case PopupId.SignOut:
      popupComponent = <SignOut />
      break
    case PopupId.ApplyToJob:
      popupComponent = <ApplyToJob />
      break
    case PopupId.NotificationNewUser:
      popupComponent = (
        <SimplePopup
          title="Thanks for signing up!"
          content="Check your inbox for links to our free plugins for use on your home machine"
        />
      )
      break
    case PopupId.NotificationOldUser:
      popupComponent = (
        <SimplePopup
          title="It looks like you've already signed up!"
          content="We've emailed you the links to our free plugins in case you need them again"
        />
      )
      break
    case PopupId.EulaAgreement:
      popupComponent = (
        <EulaAgreement />
      )
      break
    case PopupId.SoundscapeFeel:
      popupComponent = (
        <SoundscapeFeel />
      )
      break
    case PopupId.SoundscapeSmart:
      popupComponent = (
        <SoundscapeSmart />
      )
      break
    case PopupId.SoundscapeZone:
      popupComponent = (
        <SoundscapeZone />
      )
      break
    case PopupId.SoundscapeVoice:
      popupComponent = (
        <SoundscapeVoice />
      )
      break
    case PopupId.SoundscapeSafe:
      popupComponent = (
        <SoundscapeSafe />
      )
      break
  }

  if (!popupComponent) {
    return null
  }

  return (
    <PopupContainer inRouter={false}>
      { popupComponent }
    </PopupContainer>
  )
}
