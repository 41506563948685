import * as React from 'react'

import { Text, TextName } from '../text/Text'

import classes from './style.module.scss'

interface ComponentProps {
  image: string
  features: {
    icon: string
    title: string
    description: string
  }[]
}

export const ImageFeatures: React.FC<ComponentProps> = (props) => {
  const { image, features } = props

  return (
    <div className={classes.block}>
      <img
        className={classes.info}
        src={image}
        width={2368}
        height={1046}
        alt="info"
      />

      <div className={classes.features}>
        { features.map((feature, index) => (
          <div
            key={index}
            className={classes.feature}
          >
            <img src={feature.icon} alt="icon"/>
            <div>
              <Text
                type={TextName.TextBold}
                className={classes.title}
              >
                { feature.title }
              </Text>
              <Text type={TextName.Text}>
                { feature.description }
              </Text>
            </div>
          </div>
        )) }
      </div>
    </div>
  )
}
