import * as React from 'react'

import { Button } from 'sdk/button/Button'

import icon from './assets/sdk-hovered.svg'

interface ComponentProps {
  onClick?: () => void
}

export const ActionButtonGetSDK: React.FC<ComponentProps> = ({ onClick }) => {
  return (
    <Button
      iconHovered={icon}
      onClick={onClick}
      as={onClick ? 'button' : 'router-link'}
      to={onClick ? undefined : '/sdk'}
    >
      Get our SDK
    </Button>
  )
}
