import liveBroadcast from './assets/live-broadcast.jpeg'
import gaming from './assets/gaming.jpeg'
import automotive from './assets/automotive.jpeg'
import streaming from './assets/streaming.jpeg'

export const cases = [
  {
    image: liveBroadcast,
    title: 'Live & Broadcast',
  },
  {
    image: gaming,
    title: 'Gaming & VR',
  },
  {
    image: automotive,
    title: 'Automotive',
    link: '/soundscape',
  },
  {
    image: streaming,
    title: 'Streaming',
  },
]
