import * as React from 'react'

import { GradientContainer, Text, TextName } from 'sdk'
import { SimpleSection } from 'sdk/sections'

import plugin from './assets/plugin.jpeg'
import howItWorks from './assets/how-it-works.jpeg'

import classes from './style.module.scss'

export const NamPlugin: React.FC = () => {
  return (
    <SimpleSection
      title="GPU-powered NAM Plugin"
    >
      <GradientContainer className={classes.namPlugin}>
        <img
          src={plugin}
          width={440}
          height={292}
          alt="plugin"
        />

        <Text type={TextName.Text}>
          The NAM Plugin is CPU-intensive due to its ML/NN foundation,
          but GPU acceleration significantly enhances performance,
          enabling more simultaneous instances in real-time.
          <br /><br />
          With this SDK, developers can build a GPU-optimized NAM
          Plugin and run it in any VST3 or AUv2-compatible DAW.
          Additional use cases and example projects will be available soon.
        </Text>
      </GradientContainer>

      <img
        className={classes.howItWorks}
        src={howItWorks}
        alt="how-it-works"
        width={1776}
        height={619}
      />
    </SimpleSection>
  )
}
