import * as React from 'react'
import { Link, useLocation } from 'react-router-dom'

import { Text, TextName } from 'sdk'
import { CloseButton, Content } from 'sdk/components'

import arrow from './assets/arrow.svg'

import classes from './style.module.scss'

interface ComponentProps {
  onClose: () => void
}

export const SDKBadge: React.FC<ComponentProps> = ({ onClose }) => {
  const { pathname } = useLocation()

  React.useEffect(() => {
    if (pathname !== '/sdk') {
      return
    }

    onClose()
  }, [pathname])

  return (
    <Content
      mainWrapperClassName={classes.badge}
      className={classes.content}
    >
      <Text type={TextName.Subtext}>
        GPU Audio SDK is finally out! Build the Future of Audio With Our SDK.
        { ' ' }
        <Link to="/sdk" onClick={onClose}>
          Learn&nbsp;more
        </Link>
        &nbsp;
        <img
          className={classes.arrow}
          src={arrow}
          alt="arrow"
        />
      </Text>

      <CloseButton className={classes.closeButton} onClick={onClose} />
    </Content>
  )
}
