import * as React from 'react'
import { MouseParallaxChild, MouseParallaxContainer } from 'react-parallax-mouse'

import { Content } from 'sdk/components'
import { ActionButtonTryNow } from 'sdk/action-buttons/ActionButtonTryNow'
import underglow from 'shared/assets/underglow.png'

import products from './assets/products.png'

import { Text, TextName } from '../../text/Text'

import classes from './style.module.scss'

export const ExploreProducts: React.FC = () => {
  return (
    <Content
      mainWrapperClassName={classes.wrapper}
      className={classes.container}
    >
      <div className={classes.textBlock}>
        <Text
          type={TextName.H1}
          className={classes.heading}
        >
          Explore The Power of Your GPU in our Partner Products
        </Text>

        <ActionButtonTryNow />
      </div>
      <MouseParallaxContainer
        globalFactorX={0.015}
        globalFactorY={0.015}
        resetOnLeave
        useWindowMouseEvents
        containerStyle={{
          overflow: 'visible',
          position: 'absolute',
        }}
        className={classes.imageContainer}
      >
        <img
          className={classes.underglow}
          src={underglow}
          width={1860}
          height={1658}
          alt="underglow"
        />
        <MouseParallaxChild className={classes.productsImage}>
          <img
            src={products}
            width={995}
            height={709}
            alt="products"
          />
        </MouseParallaxChild>
      </MouseParallaxContainer>
    </Content>
  )
}
