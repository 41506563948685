import * as React from 'react'

import { Hero } from './sections/hero/Hero'
import { ProgrammingYourGPU } from './sections/programming-your-gpu/ProgrammingYourGPU'
import { UseCases } from './sections/use-cases/UseCases'
import { NamPlugin } from './sections/nam-plugin/NamPlugin'
import { SeeNam } from './sections/see-nam/SeeNam'
import { GettingStarted } from './sections/getting-started/GettingStarted'
import { ImplementAudio } from './sections/implement-audio/ImplementAudio'

export const SDKPage: React.FC = () => {
  return (
    <>
      <Hero />
      <ProgrammingYourGPU />
      <ImplementAudio />
      <UseCases />
      <NamPlugin />
      <SeeNam />
      <GettingStarted />
    </>
  )
}
