import * as React from 'react'
import { useLoaderData } from 'react-router-dom'

import { ProductRecord } from 'core/interfaces'

import { Downloads } from './downloads/Downloads'

import classes from './style.module.scss'

export const SDKBinaries: React.FC = () => {
  const products = useLoaderData() as ProductRecord[]

  return (
    <>
      <div className={classes.image} />

      <Downloads products={products} />
    </>
  )
}
