import * as React from 'react'

import { Content } from 'sdk/components'
import { Text, TextName } from 'sdk'

import classes from './style.module.scss'

interface ComponentProps {
  title: string
  subtitle?: string
  id?: string
}

export const SimpleSection: React.FC<React.PropsWithChildren<ComponentProps>> = (props) => {
  const { title, subtitle, id, children } = props

  return (
    <Content
      id={id}
      className={classes.content}
    >
      <div className={classes.titlesBlock}>
        <Text
          type={TextName.H2}
        >
          { title }
        </Text>

        { subtitle ? (
          <Text
            type={TextName.Text}
            className={classes.subtitle}
          >
            { subtitle }
          </Text>
        ) : null }
      </div>

      { children }
    </Content>
  )
}
