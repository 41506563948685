import * as React from 'react'

import { Content } from 'sdk/components'
import { Text, TextName, ProductItem } from 'sdk'
import { PopupId, showPopup } from 'redux/popupReducer'
import { useAppDispatch } from 'redux/store'

import { ProductRecord } from 'core/interfaces'

import classes from './style.module.scss'

interface ComponentProps {
  products: ProductRecord[]
}

export const Downloads: React.FC<ComponentProps> = ({ products }) => {
  const dispatch = useAppDispatch()

  return (
    <Content
      className={classes.content}
    >
      <Text
        type={TextName.H1}
        className={classes.title}
      >
        Downloads
      </Text>

      <Text type={TextName.Text}>
        By clicking the download button, you agree to abide by the terms
        and conditions set forth in the
        { ' ' }
        <span
          className={classes.agreementButton}
          onClick={() => {
            dispatch(showPopup(PopupId.EulaAgreement))
          }}
        >
          end-user license agreement
        </span>.
        { ' ' }
        If you do not agree to abide by these terms and conditions, you
        are not permitted to use the site or download materials from the site.
      </Text>

      <div className={classes.products}>
        { products.length && products.map((product) => (
          <ProductItem
            key={product.id}
            product={product}
          />
        )) }
      </div>
    </Content>
  )
}
