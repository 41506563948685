import * as React from 'react'
import { MouseParallaxChild, MouseParallaxContainer } from 'react-parallax-mouse'

import gpuSdk from 'shared/assets/gpu-sdk.png'
import { Content } from 'sdk/components'
import { Text, TextName } from 'sdk'
import underglow from 'shared/assets/underglow.png'
import { ActionButtonGetSDK } from 'sdk/action-buttons/ActionButtonGetSDK'

import { SectionId } from 'core/interfaces'
import { scrollToId } from 'core/utils'

import classes from './style.module.scss'

export const Hero: React.FC = () => (
  <Content
    mainWrapperClassName={classes.wrapper}
    className={classes.container}
  >
    <div className={classes.textBlock}>
      <Text
        type={TextName.H1}
        className={classes.heading}
      >
        Build the Future of Audio with&nbsp;Our&nbsp;SDK
      </Text>
      <Text
        type={TextName.Text}
        className={classes.description}
      >
        Implement your software ideas with access to
        the immense power of GPU processing to forge
        the next generation of audio experiences
      </Text>
      <ActionButtonGetSDK
        onClick={() => scrollToId(SectionId.GettingStarted)}
      />
    </div>

    <MouseParallaxContainer
      globalFactorX={0.015}
      globalFactorY={0.015}
      resetOnLeave
      useWindowMouseEvents
      containerStyle={{
        overflow: 'visible',
        position: 'absolute',
      }}
      className={classes.imageContainer}
    >
      <img
        className={classes.underglow}
        src={underglow}
        width={1860}
        height={1658}
        alt="underglow"
      />
      <MouseParallaxChild className={classes.productsImage}>
        <img
          src={gpuSdk}
          width={995}
          height={709}
          alt="products"
        />
      </MouseParallaxChild>
    </MouseParallaxContainer>
  </Content>
)
