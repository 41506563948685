import * as React from 'react'

import {
  IndustryLeaders,
  NewEra,
  ExploreProducts,
  GetOurSDK,
} from 'sdk/sections'

import { Hero } from './sections/hero/Hero'
import { NewPossibilities } from './sections/new-possibilities/NewPossibilities'
import { FlagshipReview } from './sections/flagship-review/FlagshipReview'

export const HomePage: React.FC = () => {
  return (
    <>
      <Hero />
      <NewEra />
      <NewPossibilities />
      <GetOurSDK />
      <IndustryLeaders />
      <FlagshipReview />
      <ExploreProducts />
    </>
  )
}
