import * as React from 'react'

import { GradientContainer, Text, TextName } from 'sdk'
import { SimpleSection } from 'sdk/sections'

import { useCases } from './data'

import classes from './style.module.scss'

export const UseCases: React.FC = () => {
  return (
    <SimpleSection
      title="Pro Audio Use Cases"
      subtitle="Use cases for the SDK are unlimited — here’s just a few"
    >
      <div className={classes.useCases}>
        { useCases.map(({ icon, title }, index) => (
          <GradientContainer
            key={index}
            className={classes.useCase}
          >
            <img
              src={icon}
              width={24}
              height={24}
              alt={title}
            />

            <Text type={TextName.TextBold}>
              { title }
            </Text>
          </GradientContainer>
        )) }
      </div>
    </SimpleSection>
  )
}
