import * as React from 'react'

import linkedInIcon from './assets/linkedin.svg'
import facebookIcon from './assets/facebook.svg'
import instagramIcon from './assets/instagram.svg'
import twitterIcon from './assets/twitter.svg'

import classes from './style.module.scss'

export enum SocialNetwork {
  LinkedIn = 'LinkedIn',
  Facebook = 'Facebook',
  Instagram = 'Instagram',
  Twitter = 'Twitter',
}

export interface ComponentProps {
  name: SocialNetwork;
  link: string;
}


const iconPaths: { [key in SocialNetwork]: string } = {
  [SocialNetwork.LinkedIn]: linkedInIcon,
  [SocialNetwork.Facebook]: facebookIcon,
  [SocialNetwork.Instagram]: instagramIcon,
  [SocialNetwork.Twitter]: twitterIcon,
}

export const SocialIcon: React.FC<ComponentProps> = ({ name, link }) => {
  const iconPath = iconPaths[name]

  return (
    <a
      className={classes.socialIcon}
      href={link}
      target="_blank"
      rel="noopener noreferrer"
    >
      <img src={iconPath} alt={name} />
    </a>
  )
}
